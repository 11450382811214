<template>
  <!-- eslint-disable -->
  <div>
    <add-edit :is-add.sync="isAdd" ref="areaAdd"> </add-edit>
    <b-card no-body>
      <b-card-header class="px-2 py-2 pb-0">
        <b-row class="w-100">
          <b-col lg="4" class="mb-lg-0 mb-1">
            <b-form-group label="Proyecto" label-for="project" class="">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="proyectos"
                label="code"
                input-id="project"
                :reduce="(proyectos) => proyectos.id"
                placeholder="Proyecto"
                v-model="project_id"
                class="select-obra"
                @input="filter"
                
              >
                <template v-slot:selected-option="option">
                  {{ option.code }} - {{ option.description }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.code }} - {{ option.description }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" class="mb-lg-0 mb-1">
            <b-form-group label="Tipo" label-for="tipo">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tipos"
                label="name"
                input-id="tipo"
                :reduce="(tipos) => tipos.code"
                placeholder="Tipo"
                v-model="tipo"
                @input="filter"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            lg="4"
            class="mb-lg-0 d-flex justify-content-center justify-content-lg-between align-items-center"
          >
            <div class="d-flex">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                class="mr-1"
                @click.prevent="clean"
              >
                <span class="align-middle">Ver todos</span>
              </b-button>
            </div>
            <b-button variant="primary" class="" @click="addArea">
              <span class="text-nowrap">
                <feather-icon icon="PlusCircleIcon" size="15" /> Agregar</span
              >
            </b-button>
          </b-col>
        </b-row>
        <!-- <b-row class="">
        <b-col
          md="12"
          class="d-flex justify-content-center justify-content-md-start align-items-center"
        >
          <div class="d-flex align-items-center justify-content-end">
            
          </div>
        </b-col>
      </b-row> -->
      </b-card-header>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable*/
import Vue from 'vue'
import { BootstrapVue, VBToggle } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import addEdit from './add-edit.vue'
import CategoryService from '@/services/CategoryService'
import ProjectsService from '@/services/ProjectsService'
Vue.use(BootstrapVue)
export default {
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  data() {
    return {
      isAdd: false,
      isUpdate: false,
      see_more: false,
      categoria: '',
      project_id: JSON.parse(localStorage.getItem('project_id')),
      proyectos: [],
      tipo: '001',
      tipos: [
        {
          code: '001',
          name: 'Proyecto'
        },
        {
          code: '002',
          name: 'Subcontratista / Servicios'
        },
        {
          code: '003',
          name: 'Cliente'
        },
        {
          code: '004',
          name: 'Oficina Principal'
        }
      ],
      records: [],
      showEntrie: 10,
      arrayFilters: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      user_role: JSON.parse(localStorage.getItem('userData')).role_user[0].role_restriction,
    }
  },
  components: {
    vSelect,
    addEdit
  },
  mounted() {
    this.getSelect()
    this.filter()
  },
  methods: {
    clickSeeMore() {
      this.see_more = !this.see_more
    },
    addArea() {
      this.isAdd = true
      this.$refs.areaAdd.type_filter = this.tipo
      this.$refs.areaAdd.setData({})
    },
    async getSelect() {
      const url = `?limit=100&`
      const respProyectos = await ProjectsService.getProyectos(url, this.$store)
      console.log('respProyectos', respProyectos)
      if (respProyectos.status) {
        this.proyectos = respProyectos.data.rows
        // if (respProyectos.data.rows.length > 0) {
        //   this.project_id = respProyectos.data.rows[0].id
        // } else {
        //   if (this.userData.role == 'planner') {
        //     this.project_id = 0
        //   }
        // }
      }
      // this.filter()
    },
    async filter() {
      var arrayFilter = []
      if (this.tipo != '' && this.tipo != null) {
        arrayFilter.push({
          keyContains: 'type',
          key: 'contains',
          value: this.tipo
        })
      }
      if (this.project_id != '' && this.project_id != null) {
        arrayFilter.push({
          keyContains: 'project_id',
          key: 'equals',
          value: this.project_id
        })
      }
      console.log('arrayFilter', arrayFilter)
      this.$parent.$parent.arrayFilters = arrayFilter
      this.$parent.$parent.type_filter = this.tipo
      this.$parent.$parent.getData()
    },
    clean() {
      
      this.tipo = ''
      // this.project_id = null
      var arrayFilter = []
      if (this.user_role != 'admin') {
        const proyects = []
        const types = []
        for (let index = 0; index < this.tipos.length; index++) {
          const element = this.tipos[index]
          types.push(element.code)
        }
        // if (this.proyectos.length > 0) {
        //   for (let index = 0; index < this.proyectos.length; index++) {
        //     const element = this.proyectos[index]
        //     proyects.push(element.id)
        //   }
        // }
        // if (proyects.length > 0) {
        //   arrayFilter.push({
        //     keyContains: 'project_id',
        //     key: 'in',
        //     value: JSON.stringify(proyects)
        //   })
        // }
        if (types.length > 0) {
          arrayFilter.push({
            keyContains: 'type',
            key: 'in',
            value: JSON.stringify(types)
          })
        }
      }
      arrayFilter.push({
        keyContains: 'project_id',
        key: 'equals',
        value: this.project_id
      })
      this.$parent.$parent.arrayFilters = arrayFilter
      this.$parent.$parent.getData()
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.body_card {
  display: block;
  transition: 0.3s;
}
</style>
